<template>
  <v-data-table :headers="headerComputed" :items="desserts" sort-by="no" dense>
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn color="success" dark rounded :to="{ name: 'Create Budget' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu offset-y :close-on-content-click="false" allow-overflow max-height="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-list dense flat>
            <v-list-item v-for="(header, i) in headers" :key="i">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    v-model="headerInitTmp"
                    :input-value="active"
                    :value="header"
                    @change="onInputSelect(header, $event, i)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ header.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ desserts.map(x => x.analysName).indexOf(item.analysName) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Year",
        value: "year",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Month",
        value: "month",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Analys Name",
        value: "analysName",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Notes",
        value: "note",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Project",
        value: "project",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Departement",
        value: "departement",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    headerInit: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Year",
        value: "year",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Month",
        value: "month",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Analys Name",
        value: "analysName",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Notes",
        value: "note",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    headerInitTmp: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Year",
        value: "year",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Month",
        value: "month",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Analys Name",
        value: "analysName",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Notes",
        value: "note",
        sortable: false,
        align: "start",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    desserts: [],
  }),

  computed: {
    headerComputed() {
      return this.headerInit;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [
        {
          year: "2020",
          month: "January",
          type: "General",
          analysName: "Pemasukan Awal Bulan",
          note: "",
          project: "",
          departement: "",
        },
        {
          year: "2020",
          month: "February",
          type: "General",
          analysName: "Pemasukan Bulan Kedua",
          note: "",
          project: "",
          departement: "",
        },
        {
          year: "2021",
          month: "January",
          type: "General",
          analysName: "Pemasukan Awal Bulan Tahun Depan",
          note: "",
          project: "",
          departement: "",
        },
      ];
    },
    onInputSelect(oldItem) {
      if (!this.headerInit.some(val => val.id === oldItem.id)) {
        for (let index = 0; index < this.headerInit.length; index++) {
          if (oldItem.id < this.headerInit[index].id) {
            this.headerInit.splice(index, 0, oldItem);
            break;
          } else if (this.headerInit[this.headerInit.length - 1].id < oldItem.id) {
            this.headerInit.splice(this.headerInit.length, 0, oldItem);
            break;
          }
        }
      } else {
        this.headerInit.splice(
          this.headerInit.findIndex(val => val.id === oldItem.id),
          1
        );
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
